// Filename: useAuthCheck.js
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuthCheck = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(null); // null initially
    const navigate = useNavigate();
    const api_url = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const checkSession = async () => {
            try {
                const response = await fetch(`${api_url}/check-session`, {
                    credentials: 'include',
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setIsAuthenticated(data.isAuthenticated);
                if (!data.isAuthenticated) {
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error checking session:', error);
                navigate('/login');
            }
        };
        checkSession();
    }, [api_url, navigate]);

    // Returning isAuthenticated allows components to adjust their rendering
    // based on the authentication state if needed.
    return isAuthenticated;
};

export default useAuthCheck;
