import React, { useEffect } from 'react';
import { H1Sans, H3Sans, P14Sans } from '../uiKit/Fonts';
import { DashboardNav } from '../uiKit/dashboardNav/DashboardNav';
import useAuthCheck from '../authenticate/useAuthCheck';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import LoadingPage from '../uiKit/loadingStates/LoadingPage';
import './meanReversionReport.scss';
import { fetchSpecificMrTests } from '../store/specificMrTestsSlice';
import { MrTableSubTestListRow } from './components/MrTableListRow';
import { useNavigate } from 'react-router-dom';
import useMRTestValues from './components/useMRTestValues';
import { WhiteIconOnly } from '../uiKit/Buttons';
import { ResultCard } from './components/ResultCard';
import { formatTimespan, formatSmallFraction } from './components/utils';
import LoadingSectionMRListPage from '../uiKit/loadingStates/LoadingSectionMRListPage';
import { EsTsGroupedBarChart } from './components/EsTsGroupedBarChart';

import { toast, Slide } from 'react-toastify';

import io from 'socket.io-client';
const api_url = process.env.REACT_APP_API_URL;
const socket = io(api_url, { withCredentials: true });




function MeanReversionReport () {

    // consts
    const dispatch = useDispatch();
    const { specificMrTests } = useSelector((state) => state.specificMrTests);
    const isAuthenticated = useAuthCheck();
    const { tickers } = useParams();
    const [ticker1, ticker2] = tickers.split("-");
    const navigate = useNavigate();

    const { windowStartDateMonth,
        windowStartDateYear,
        windowEndDateMonth,
        windowEndDateYear,
        tsLevel,
        esLevel,
        eigenvalue,
        halfLife,
        nonMainMrTests } = useMRTestValues(ticker1, ticker2);

    // Toast creation
    const showToast = (message) => {
        toast(message, {
            position: "bottom-left",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            className: 'kit-custom-toast-white',
            transition: Slide,
        });
    };

    // authentication, websocket, featching from slice
    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchSpecificMrTests({ ticker1: ticker1, ticker2: ticker2 }));
        }
        // Listen for real-time MR test updates
        socket.on('mrTestUpdate', () => {
            dispatch(fetchSpecificMrTests({ ticker1: ticker1, ticker2: ticker2 }));
        });
        return () => {
            socket.off('mrTestUpdate');
        };
    }, [dispatch, isAuthenticated, ticker1, ticker2]);

    // Prioritize checking for authentication status
    if (isAuthenticated === null) {return <LoadingPage />};

    // Check if mainMrTests is not empty before trying to access its properties
    const hasSpecificMrTests = specificMrTests && specificMrTests.length > 0;

    // delete strategy action
    const deleteStrategy = async (ticker1, ticker2) => {
        try {
            const response = await fetch(`${api_url}/mr/delete-strategy/${ticker1}-${ticker2}`, {
                method: 'POST',
                credentials: 'include', // Needed for cookies to be set
                headers: {'Content-Type': 'application/json',}
            });
            if (response.ok && response.headers.get("Content-Type").includes("application/json")) {
                await response.json(); // Always parse JSON to check for server-sent messages
                showToast('Strategy deleted successfully');
                navigate('/mr');
            } else {showToast(`Error: ${response.statusText}`)}
        } catch (error) {
            console.error('Error:', error);
            showToast('An unexpected error occurred. Please try again.');
        }
    };

    const onClickGoBack = () => {navigate('/mr')}

    // process data for charting
    const processData = (nonMainMrTests) => {
        const halfYearlyData = {};
    
        nonMainMrTests.forEach(test => {
            const startDate = new Date(test.window_start_date);
            const half = startDate.getMonth() < 6 ? 'H1' : 'H2';
            const year = startDate.getFullYear();
            const key = `${half} ${year}`;
    
            if (!halfYearlyData[key]) {
                halfYearlyData[key] = {
                    ts_level: 0,
                    es_level: 0,
                    period: key,
                };
            }
    
            // Example of simple aggregation - adjust this logic to suit how you want to represent ts_level and es_level
            // Here we simply count occurrences. You might want to average, sum, or otherwise aggregate these values.
            halfYearlyData[key].ts_level += parseInt(test.ts_level, 10); // Assuming ts_level is '90', '95', etc.
            halfYearlyData[key].es_level += parseInt(test.es_level, 10); // Assuming es_level is '90', '95', etc.
        });
    
        return Object.values(halfYearlyData);
    };

    const processedData = processData(nonMainMrTests.sort((a, b) => new Date(a.window_start_date) - new Date(b.window_start_date)));

    // JSX component
    return (
        <div className='mrr-main_w'>
            <DashboardNav/>
            <div className='mrr-col_2-main_w'>
                <div className='mrr-col_2-header_parent_w'>
                    <WhiteIconOnly iconName='arrow_back' onClick={onClickGoBack}/> 
                    <div className='mrr-col_2-header_text_w'>
                        <H1Sans copy={`${ticker1}-${ticker2} Mean Reversion Report`}/>
                        <P14Sans copy={`${windowStartDateMonth} ${windowStartDateYear} to ${windowEndDateMonth} ${windowEndDateYear}`}/>
                    </div>
                    <WhiteIconOnly iconName='delete' onClick={() => deleteStrategy(ticker1, ticker2)}/> 
                </div>
                <div className='mrr-col_2-body_w'>
                    <div className='mrr-col_2-result_card-parent_w'>
                        <ResultCard label='Trace Stat Level' value={tsLevel}/>
                        <ResultCard label='Eigen Stat Level' value={esLevel}/>
                        <ResultCard label='Eigenvalue' value={formatSmallFraction(eigenvalue)}/>
                        <ResultCard label='Halflife' value={formatTimespan(halfLife)}/>
                    </div>
                    <EsTsGroupedBarChart data={processedData} />
                    <div className='mrr-col_2-section_w'>
                        <H3Sans copy='Data Table'/>
                        <div className='mrr-col_2-data_table_w'>
                            <div className='mrr-test_list-header_w'>
                                <div className='mrr-test_list-header_cell_w'>
                                    <span>Start date</span>
                                </div>
                                <div className='mrr-test_list-header_cell_w'>
                                    <span>End date</span>
                                </div>
                                <div className='mrr-test_list-header_cell_w'>
                                    <span>Trace Stat Level</span>
                                </div>
                                <div className='mrr-test_list-header_cell_w'>
                                    <span>Eigen Stat Level</span>
                                </div>
                                <div className='mrr-test_list-header_cell_w'>
                                    <span>Eigenvalue</span>
                                </div>
                                <div className='mrr-test_list-header_cell_w'>
                                    <span>Halflife</span>
                                </div>
                                <div className='mrr-test_list-header_cell_w'>
                                    <span>Status</span>
                                </div>
                            </div>
                            {hasSpecificMrTests ? 
                                nonMainMrTests
                                .sort((a, b) => new Date(a.window_start_date) - new Date(b.window_start_date)) // Sorting by window_start_date
                                .map(test => <MrTableSubTestListRow key={test.id} mainMrObj={test}/>) 
                                : <LoadingSectionMRListPage/>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default MeanReversionReport;