import React from 'react';
import './loadingSectionMRListPage.scss';

function LoadingSectionMRListPage() {

    return (
        <div className='loadingSection-mr_list-main_w'>
            <div className='loadingSection-mr_list-row_w'>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
            </div>
            <div className='loadingSection-mr_list-row_w'>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
            </div>
            <div className='loadingSection-mr_list-row_w'>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
                <div className='loadingSection-mr_list-cell_w'></div>
            </div>
            <div className='loadingSection-title_w'>
                <span>Nothing to show yet</span>
            </div>
        </div>
    )
};

export default LoadingSectionMRListPage;