import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPublicMonitoringData, fetchToneMonitoringData, fetchHistoricStockMonitoringData } from '../store/monitorSlice';




export function usePublicMonitoringData() {
    const dispatch = useDispatch();
    const monitoringDataMainObj = useSelector((state) => state.monitoringData.publicMonitoringData);

    useEffect(() => {
        dispatch(fetchPublicMonitoringData());
    }, [dispatch]);

    return monitoringDataMainObj;
};




export function useToneMonitoringData() {
    const dispatch = useDispatch();
    const toneMonitoringDataMainObj = useSelector((state) => state.monitoringData.toneMonitoringData);

    useEffect(() => {
        dispatch(fetchToneMonitoringData());
    }, [dispatch]);

    return toneMonitoringDataMainObj;
}




export function useHistoricStockMonitoringData() {
    const dispatch = useDispatch();
    const historicStockMonitoringDataMainObj = useSelector((state) => state.monitoringData.historicStockMonitoringData);

    useEffect(() => {
        dispatch(fetchHistoricStockMonitoringData());
    }, [dispatch]);

    return historicStockMonitoringDataMainObj;
}