import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link12Grey, Link12Black, ButtonMainLarge } from '../uiKit/Buttons';
import { H1Mono } from '../uiKit/Fonts';
import { InputBasic } from '../uiKit/Inputs';
import registerImage from '../assets/signup.png';

import { toast, Slide } from 'react-toastify';
import './register.scss';

function Register() {
    
    // consts
    const [inviteCode, setInviteCode] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const api_url = process.env.REACT_APP_API_URL;
    
    // reusable toast
    const showToast = (message) => {
        toast(message, {
            position: "bottom-left",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            className: 'kit-custom-toast-white',
            transition: Slide,
        });
    };

    // function when clicking on the button
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate input fields
        if (!username || !email || !password || !inviteCode) {
            showToast('You forgot to fill some fields.');
            return;
        }

        try {
            const result = await fetch(`${api_url}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    inviteCode, 
                    username,
                    email,
                    password
                })
            });

            if (result.status === 200) {
                showToast('Registration successful. Redirecting...');
                navigate('/login');
            } else {
                const errorResponse = await result.json(); // Assuming the server sends a JSON error message
                showToast(errorResponse.message || 'Error creating an account, try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            showToast('Sorry, unknown error. Please try again later.');
        }
        
        
    };




    // element
    return (
        <div className='rp-main_w'>
            <div className='rp-col_1_w'>
                <div className='rp-col_1-header_w'>
                    <Link12Black href='/' copy='Home'/>
                </div>
                <div className='rp-col_1-body_w'>
                    <H1Mono copy="Register"/> 
                    <form className='rp-form_w'>
                        <InputBasic label="Invite code" placeholder='123456' type="text" value={inviteCode} onChange={e => setInviteCode(e.target.value)}/>
                        <InputBasic label="Username" placeholder='GordonG' type="text" value={username} onChange={e => setUsername(e.target.value)}/>
                        <InputBasic label="Email" placeholder='gordon@email.com' type="email" value={email} onChange={e => setEmail(e.target.value)}/>
                        <InputBasic label="Password" type="password" placeholder='*************' value={password} onChange={e => setPassword(e.target.value)}/>
                        <div className='rp-form-cta_w'>
                            <ButtonMainLarge onClick={handleSubmit} copy="Register"/>
                        </div>
                    </form>
                </div>
                <div className='lp-col_1-footer_w'>
                    <Link12Grey href='/login' copy='Login instead'/>
                </div>
            </div>
            <div className='rp-col_2_w'>
                <img className='lp-col_2-image' alt='inspirational shape' src={registerImage} />
            </div>
        </div>
    );
}

export default Register;
