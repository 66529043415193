import React from 'react';
import './passwordReset.scss';
import { H1Sans } from '../uiKit/Fonts';
import { ButtonMain } from '../uiKit/Buttons';
import { useNavigate } from 'react-router-dom';


function PasswordReset() {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/login');
    };

    return (
        <div className='pass_reset-main_w'>
            <div className='pass_reset-inner_w'>
                <H1Sans copy="Sorry, you cannot reset passwords yet." />
                <ButtonMain onClick={handleClick} copy="Try to login again"/>
            </div>
        </div>
    );
}

export default PasswordReset;