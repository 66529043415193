import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './authenticate/login.js';
import Account from './authenticate/Account.js';
import MeanReversion from './mr/MeanReversion.js';
import Backtest from './backtest/Backtest.js';
import Register from './authenticate/register.js';
import HomePage from './growth/HomePage';
import Download from './download/Download.js';
import PasswordReset from './authenticate/PasswordReset';
import { Monitor } from './monitor/Monitor';
import MeanReversionReport from './mr/MeanReversionReport';
import LoadingPage from './uiKit/loadingStates/LoadingPage.js';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/download" element={<Download />} />
        <Route path="/loading" element={<LoadingPage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/monitor" element={<Monitor />} />
        <Route path="/reset-password" element={<PasswordReset />} />
        <Route path="/account" element={<Account />} />
        <Route path="/mr-report/:tickers" element={<MeanReversionReport />} />
        <Route path="/mr" element={<MeanReversion />} />
        <Route path="/backtest" element={<Backtest />} />
      </Routes>
      <ToastContainer limit={3}/>
    </BrowserRouter>
  );
}

export default App;