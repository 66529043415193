import React from 'react';
import './mrTableListRow.scss';
import { useNavigate } from 'react-router-dom';
import { BlackIconOnly } from '../../uiKit/Buttons';
import { formatSmallFraction, formatTimespan, formatDate } from './utils';


function MrTableMainTestListRow({mainMrObj}) {

    const navigate = useNavigate();

    const openMrTestReport = () => {
        navigate(`/mr-report/${mainMrObj.ticker_symbol_1}-${mainMrObj.ticker_symbol_2}`)
    };


    return (
        <div className='mainMrObj-row-parent_w'>
            <div className='mainMrObj-row-cell_w'>
                <span>{formatDate(mainMrObj.mr_test_date)}</span>
            </div>
            <div className='mainMrObj-row-cell_w'>
                <span>{mainMrObj.ticker_symbol_1}</span>
            </div>
            <div className='mainMrObj-row-cell_w'>
                <span>{mainMrObj.ticker_symbol_2}</span>
            </div>
            <div className='mainMrObj-row-cell_w'>
                <span>{formatDate(mainMrObj.window_start_date)}</span>
            </div>
            <div className='mainMrObj-row-cell_w'>
                <span>{formatDate(mainMrObj.window_end_date)}</span>
            </div>
            <div className='mainMrObj-row-cell_w'>
                <span>{mainMrObj.ts_level}</span>
            </div>
            <div className='mainMrObj-row-cell_w'>
                <span>{mainMrObj.es_level}</span>
            </div>
            <div className='mainMrObj-row-cell_w'>
                <span>{formatSmallFraction(mainMrObj.eigenvalue)}</span>
            </div>
            <div className='mainMrObj-row-cell_w'>
                <span>{formatTimespan(mainMrObj.half_life)}</span>
            </div>
            <div className='mainMrObj-row-cell_w'>
                <span>{mainMrObj.test_status}</span>
            </div>
            <div className='mainMrObj-row-end_cell_w'>
                <BlackIconOnly onClick ={openMrTestReport} iconName='arrow_forward'/>
            </div>
        </div>
    );
};










function MrTableSubTestListRow({mainMrObj}) {
    return (
        <div className='mainMrObj-row-parent_w'>
            <div className='mainMrObj-row-cell_w'>
                <span>{formatDate(mainMrObj.window_start_date)}</span>
            </div>
            <div className='mainMrObj-row-cell_w'>
                <span>{formatDate(mainMrObj.window_end_date)}</span>
            </div>
            <div className='mainMrObj-row-cell_w'>
                <span>{mainMrObj.ts_level}</span>
            </div>
            <div className='mainMrObj-row-cell_w'>
                <span>{mainMrObj.es_level}</span>
            </div>
            <div className='mainMrObj-row-cell_w'>
                <span>{formatSmallFraction(mainMrObj.eigenvalue)}</span>
            </div>
            <div className='mainMrObj-row-cell_w'>
                <span>{formatTimespan(mainMrObj.half_life)}</span>
            </div>
            <div className='mainMrObj-row-cell_w'>
                <span>{mainMrObj.test_status}</span>
            </div>
        </div>
    );
};








export { MrTableMainTestListRow, MrTableSubTestListRow };