import { configureStore } from '@reduxjs/toolkit';
import publicDataReducer from './publicDataSlice';
import userDataReducer from './userDataSlice';
import companyDataReducer from './companyDataSlice';
import toneDataReducer from './toneDataSlice';
import sentimentDataReducer from './sentimentDataSlice';
import granularStockDataReducer from './granularStockDataSlice';
import historicStockDataReducer from './historicStockDataSlice';
import monitoringDataReducer from './monitorSlice';
import mainMrTestsReducer from './mainMrTestsSlice';
import specificMrTestsReducer from './specificMrTestsSlice';


export const store = configureStore({
  reducer: {
    publicData: publicDataReducer,
    companyData: companyDataReducer,
    toneData: toneDataReducer,
    sentimentData: sentimentDataReducer,
    granularStockData: granularStockDataReducer,
    historicStockData: historicStockDataReducer,
    monitoringData: monitoringDataReducer,
    userData: userDataReducer,
    mainMrTests: mainMrTestsReducer,
    specificMrTests: specificMrTestsReducer
  },
});