import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const api_url = process.env.REACT_APP_API_URL;

// Async thunk action for fetching public data
export const fetchGranularStockDataForCompany = createAsyncThunk(
  'granularStockData/fetch',
  async (companyTicker, thunkAPI) => {
    try {
      const response = await fetch(
        `${api_url}/granular_stock_data/company_ticker/${companyTicker}`, 
        { credentials: 'include' }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Initial state
const initialState = {
    granularStockData: [],
    isLoading: false,
    error: null,
};

// Slice definition
const granularStockDataSlice = createSlice({
  name: 'granularStockData',
  initialState,
  reducers: {
    // Reducer methods
  },
  extraReducers: {
    [fetchGranularStockDataForCompany.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchGranularStockDataForCompany.fulfilled]: (state, action) => {
      state.isLoading = false;
      // IMPORTANT line here below
      state.granularStockData = action.payload;
    },
    [fetchGranularStockDataForCompany.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default granularStockDataSlice.reducer;