import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, Slide } from 'react-toastify';
import './newMrTestForm.scss';
import { fetchAllCompanyData } from '../../store/companyDataSlice';
import { InputDropdown } from '../../uiKit/Inputs';
import { ButtonMainLarge } from '../../uiKit/Buttons';



function NewMrTestForm() {
    // consts
    const [tickerName1, setTickerName1] = useState('');
    const [tickerName2, setTickerName2] = useState('');
    const [startYear, setStartYear] = useState(2017);
    const [endYear, setEndYear] = useState(2021);
    const [minYearAvailable, setMinYearAvailable] = useState(Infinity);
    const [maxYearAvailable, setMaxYearAvailable] = useState(-Infinity);
    const api_url = process.env.REACT_APP_API_URL;
    const dispatch = useDispatch();
    const { allCompanyData } = useSelector((state) => state.companyData);

    const generateOptions = (start, end) => {
        const options = [];
        for (let i = start; i <= end; i++) {
            options.push({ value: i, label: i });
        }
        return options;
    };

    // useEffect to dispatch action for fetching all company data
    useEffect(() => {
        dispatch(fetchAllCompanyData());
    }, [dispatch]);

    // useEffect for initializing tickerName1 and tickerName2
    useEffect(() => {
        if (allCompanyData.length > 0) {
            setTickerName1(allCompanyData[0].ticker_symbol);
            setTickerName2(allCompanyData[1].ticker_symbol);
        }
    }, [allCompanyData]);

    // useEffect to update startYear and endYear based on selected tickers
    useEffect(() => {
        const getYearFromDate = (dateString) => new Date(dateString).getFullYear();
        const findOverlapYearRange = (company1, company2) => {
            const startYear1 = getYearFromDate(company1.earliest_timestamp);
            const endYear1 = getYearFromDate(company1.latest_timestamp);
            const startYear2 = getYearFromDate(company2.earliest_timestamp);
            const endYear2 = getYearFromDate(company2.latest_timestamp);
        
            const startYear = Math.max(startYear1, startYear2); // latest of the earliest dates
            const endYear = Math.min(endYear1, endYear2); // earliest of the latest dates
        
            return { startYear, endYear };
        };
        const company1 = allCompanyData.find(company => company.ticker_symbol === tickerName1);
        const company2 = allCompanyData.find(company => company.ticker_symbol === tickerName2);

        if (company1 && company2) {
            const { startYear, endYear } = findOverlapYearRange(company1, company2);
            setMinYearAvailable(startYear);
            setMaxYearAvailable(endYear);
        }
    }, [tickerName1, tickerName2, allCompanyData]);

    // show toasts
    const showToast = (message) => {
        toast(message, {
            position: "bottom-left",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            className: 'kit-custom-toast-white',
            transition: Slide,
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate input fields
        if (!tickerName1 || !tickerName2 || !startYear || !endYear) {
            showToast('You forgot to fill some fields.');
            return;
        }

        // Validate input fields
        if (tickerName1 === tickerName2) {
            showToast('Mean reversion on itself is not a smart idea');
            return;
        }

        // Validate input fields
        if (startYear === endYear || startYear >= endYear) {
            showToast('Dates are incoherent');
            return;
        }

        // Validate input fields
        if (endYear - startYear < 1) {
            showToast('Time lapse should be at least 2 years');
            return;
        }

        // Concatenate start and end dates
        const startDate = `${startYear}-1-1`;
        const endDate = `${endYear}-12-31`;

        // Data to send
        const dataToSend = {
            tickerName1,
            tickerName2,
            startDate,
            endDate
        };

        // Send data to backend
        try {
            const response = await fetch(`${api_url}/mr/create-new-mr-test`, {
                method: 'POST',
                credentials: 'include', // Needed for cookies to be set
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSend),
            });

            if (response.status === 200) {
                const jsonResponse = await response.json();
                showToast(jsonResponse.message);
            } else {
                const jsonResponse = await response.json();
                showToast(jsonResponse.message);
            }

        } catch (error) {
            showToast(error.message);
        }
    };
    

    // JSX element
    return (
        <form className='mr_list-form_parent_w' onSubmit={handleSubmit}>
            <InputDropdown 
                label="Ticker Name 1"
                name="ticker-value-1"
                value={tickerName1}
                onChange={(e) => setTickerName1(e.target.value)}
                options={allCompanyData.map(company => ({
                    label: `${company.ticker_symbol} - ${company.name}`,
                    value: company.ticker_symbol
                }))}
            />
            <InputDropdown 
                label="Ticker Name 2"
                name="ticker-value-2"
                value={tickerName2}
                onChange={(e) => setTickerName2(e.target.value)}
                options={allCompanyData.map(company => ({
                    label: `${company.ticker_symbol} - ${company.name}`,
                    value: company.ticker_symbol
                }))}
            />
            <InputDropdown 
                label="Start Year"
                name="start-year"
                value={startYear}
                onChange={(e) => setStartYear(e.target.value)}
                options={generateOptions(minYearAvailable, maxYearAvailable)}
            />
            <InputDropdown 
                label="End Year"
                name="end-year"
                value={endYear}
                onChange={(e) => setEndYear(e.target.value)}
                options={generateOptions(minYearAvailable, maxYearAvailable)}
            />
            <div className='mr_list-form-button_w'>
                <ButtonMainLarge onClick={handleSubmit} copy='Create' />
            </div>
        </form>
    );
}



export default NewMrTestForm;
