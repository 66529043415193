import React, { useEffect } from 'react';
import useAuthCheck from './useAuthCheck';
import { H1Sans, H2Sans, P14Sans } from '../uiKit/Fonts';
import { DashboardNav } from '../uiKit/dashboardNav/DashboardNav';
import LogoutButton from './authButtons/LogoutButton';
import LoadingPage from '../uiKit/loadingStates/LoadingPage';
import './account.scss';

import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData } from '../../src/store/userDataSlice';

function Account() {
    
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state.userData);
    const isAuthenticated = useAuthCheck();

    // Dispatch fetchUserData only if isAuthenticated is true
    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchUserData());
        }
    }, [dispatch, isAuthenticated]);

    // Prioritize checking for authentication status
    if (isAuthenticated === null) {
        return <LoadingPage />;
    }

    // Assuming user data is available at this point
    const username = userData?.username;
    const email = userData?.email;
    const membership_tier = userData?.membership_tier;

    return (
        <div className='acc-parent_w'>
            <DashboardNav />
            <div className='acc-col_2_w'>
                <H1Sans copy='Your Account'/>
                <div className='acc-col_2-section_w'>
                    <H2Sans copy='Information'/>
                    <P14Sans copy={`Username: ${username}`} />
                    <P14Sans copy={`Email: ${email}`} />
                    <P14Sans copy={`Membership: ${membership_tier}`} />
                </div>
                <div className='acc-col_2-section_w'>
                    <H2Sans copy='Session'/>
                    <P14Sans copy='You are currently logged in'/>
                    <LogoutButton />
                </div>
            </div>
        </div>
    );
}

export default Account