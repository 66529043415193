import React, { useEffect } from 'react';
import useAuthCheck from '../authenticate/useAuthCheck';
// UI
import { H1Sans } from '../uiKit/Fonts';
import { ButtonPrimary } from '../uiKit/Buttons';
import { DashboardNav } from '../uiKit/dashboardNav/DashboardNav';
import './download.scss'
import LoadingPage from '../uiKit/loadingStates/LoadingPage';
import DownloadRow from './components/DownloadRow';
import { sidePanelTrigger, SidePanel } from '../uiKit/sidePanel/SidePanel';
import DownloadForm from './components/DownloadForm';
// Redux store
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllCompanyData } from '../store/companyDataSlice';
// Socket
import io from 'socket.io-client';
const api_url = process.env.REACT_APP_API_URL;
const socket = io(api_url, { withCredentials: true });



export default function Download() {

    // consts
    const dispatch = useDispatch();
    const { allCompanyData } = useSelector((state) => state.companyData);
    const isAuthenticated = useAuthCheck();

    // check authentication
    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchAllCompanyData());
        }
        // Listen for real-time MR test updates
        socket.on('companyDataUpdate', (data) => {
            console.log(data.message);
            dispatch(fetchAllCompanyData());
        });

        return () => {
            socket.off('companyDataUpdate');
        };

    }, [dispatch, isAuthenticated]);

    // Checking for authentication status
    if (isAuthenticated === null) {
        return <LoadingPage />;
    }

    // Check if mainMrTests is not empty before trying to access its properties
    const hasAllCompanyData = allCompanyData && allCompanyData.length > 0;

    // header rows
    const headerRowsArray = ['Ticker', 'Name', 'Start date', 'End date', 'Status', 'Delete'];

    const onClickDownloadNewData = () => {
        sidePanelTrigger.open({ title: 'Download new ticker' });
    };


    return (
        <>
            <div className='do-parent_w'>
                <DashboardNav />
                <div className='do-col_2_w'>
                    <div className='do-col_2-header_w'>
                        <H1Sans copy='Dowload data'/>
                        <ButtonPrimary copy='Add new' onClick={onClickDownloadNewData}/>
                    </div>
                    {hasAllCompanyData 
                        ? 
                            <div className="do-table-parent_w" >
                                <div className="do-table-header_w">
                                    {headerRowsArray.map(i => <div><span>{i}</span></div>)}
                                </div>
                                {allCompanyData.map(company => <DownloadRow company={company}/>)}
                            </div> 
                        : 
                            <p>Loading...</p>
                    }
                </div>
            </div>
            <SidePanel>
                <DownloadForm/>
            </SidePanel>
        </>
    )
}
