

export function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
        month: 'short', // "Mar"
        day: 'numeric', // "13"
        year: 'numeric', // "2024"
    });
};

export function formatTimespan(minutes) {
    const minutesInHour = 60;
    const hoursInTradingDay = 6.5;
    const minutesInTradingDay = hoursInTradingDay * minutesInHour;
  
    // Calculate the number of trading days
    const days = Math.floor(minutes / minutesInTradingDay);
    minutes -= days * minutesInTradingDay;
  
    // Calculate the number of hours
    const hours = Math.floor(minutes / minutesInHour);
    minutes -= hours * minutesInHour;
  
    // Remaining minutes
    minutes = Math.round(minutes); // Round to the nearest whole number
  
    // Construct the formatted string
    let formatted = "";
    if (days > 0) formatted += `${days}d `;
    if (hours > 0 || days > 0) formatted += `${hours}h `;
    if (minutes > 0 || hours > 0 || days > 0) formatted += `${minutes}m`;
  
    return formatted.trim();
}

export function formatSmallFraction(value) {

    const number = Number(value);

    // Validate if the conversion is successful
    if (isNaN(number)) {
        return 'Invalid number';
    }

    // Determine the formatting based on the magnitude of the number
    if (number === 0) {
        return '0';
    } else if (number > 0 && number < 1e-6) {
        // For very small numbers, use scientific notation
        return number.toExponential(2);
    } else {
        // For numbers greater than or equal to 1e-6, show up to 8 decimal places
        return number.toFixed(8);
    }
}