import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonMain } from '../../uiKit/Buttons';

const LoginButton = () => {
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate('/login');
    };

    return (
        <ButtonMain onClick={handleLogin} copy="Login"/>
    );
};

export default LoginButton;
