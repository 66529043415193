import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonPrimary } from '../../uiKit/Buttons';

const RegisterButton = () => {
    const navigate = useNavigate();

    const handleRegister = () => {
        navigate('/register');
    };

    return (
        <ButtonPrimary onClick={handleRegister} copy="Register"/>
    );
};

export default RegisterButton;
