import React from 'react';
import './monitor.css';
import { H3Sans } from '../uiKit/Fonts';
import { DashboardNav } from '../uiKit/dashboardNav/DashboardNav';
import { KPIDataCard } from '../uiKit/monitor/KPIDataCard';
import { usePublicMonitoringData, useToneMonitoringData, useHistoricStockMonitoringData } from './useMonitoringData';

export function Monitor () {
    // public data monitoring
    const newsMonitoringData = usePublicMonitoringData();
    const newsMonitoringContent = [
        {iconName:"newspaper", tag:'News', kpi:newsMonitoringData.total_rows, meta:'All datapoints'},
        {iconName:"newspaper", tag:'News', kpi:newsMonitoringData.oldest_date, meta:'Earliest datapoint'},
        {iconName:"newspaper", tag:'News', kpi:newsMonitoringData.newest_date, meta:'Latest datapoint'},
        {iconName:"newspaper", tag:'News', kpi:newsMonitoringData.avg_new_rows_per_day, meta:'Ave new datapoints each day'},
        {iconName:"newspaper", tag:'News', kpi:newsMonitoringData.avg_content_char_count, meta:'Ave content length'},
        {iconName:"newspaper", tag:'News', kpi:newsMonitoringData.percentage_processed_true, meta:'News processed for sentiment'}
    ];

    // tone data monitoring
    const toneMonitoringData = useToneMonitoringData();
    const toneMonirotingContent = [
        {iconName:"radio", tag:'Tone', kpi:toneMonitoringData.sum_all_articles, meta:'All tone datapoints'},
        {iconName:"radio", tag:'Tone', kpi:toneMonitoringData.earliest_date, meta:'Earliest data for tone'},
        {iconName:"radio", tag:'Tone', kpi:toneMonitoringData.latest_date, meta:'Latest data for tone'},
        {iconName:"radio", tag:'Tone', kpi:toneMonitoringData.avg_articles_per_day, meta:'Ave new tone data each day'}
    ];

    // historic stock data monitoring
    const historicStockMonitoringData = useHistoricStockMonitoringData();
    const historicStockMonirotingContent = [
        {iconName:"money", tag:'Stock', kpi:historicStockMonitoringData.earliest_timestamp, meta:'Earliest stock data'},
        {iconName:"money", tag:'Stock', kpi:historicStockMonitoringData.latest_timestamp, meta:'Earliest stock data'},
        {iconName:"money", tag:'Stock', kpi:historicStockMonitoringData.avg_records_per_day, meta:'Ave stock data per day'},
        {iconName:"money", tag:'Stock', kpi:historicStockMonitoringData.total_records, meta:'Total stock data points'}
    ];

    return (
        <div className='mo-main_w'>
            <DashboardNav />
            <div className='mo-col_2_w'>
                <H3Sans copy="Monitoring dashboard"/>
                <div className='mo-analytics_card_w'>
                    {newsMonitoringContent.map((e, index) => (<KPIDataCard key={index} iconName={e.iconName} tag={e.tag} kpi={e.kpi} meta={e.meta}/>))}
                </div>
                <div className='mo-analytics_card_w'>
                    {toneMonirotingContent.map((e, index) => (<KPIDataCard key={index} iconName={e.iconName} tag={e.tag} kpi={e.kpi} meta={e.meta}/>))}
                </div>
                <div className='mo-analytics_card_w'>
                    {historicStockMonirotingContent.map((e, index) => (<KPIDataCard key={index} iconName={e.iconName} tag={e.tag} kpi={e.kpi} meta={e.meta}/>))}
                </div>
            </div>
        </div>
    );
};