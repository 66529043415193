import React from 'react';

export const SvgDownloadComponent = ({ className, width = "80", height = "80" }) => (
    <svg className={className} width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill='var(--svg-fill-color)' d="M55.3 31.6665H50V14.9998C50 13.1665 48.5 11.6665 46.6666 11.6665H33.3333C31.5 11.6665 30 13.1665 30 14.9998V31.6665H24.7C21.7333 31.6665 20.2333 35.2665 22.3333 37.3665L37.6333 52.6665C38.9333 53.9665 41.0333 53.9665 42.3333 52.6665L57.6333 37.3665C59.7333 35.2665 58.2666 31.6665 55.3 31.6665ZM16.6666 64.9998C16.6666 66.8332 18.1666 68.3332 20 68.3332H60C61.8333 68.3332 63.3333 66.8332 63.3333 64.9998C63.3333 63.1665 61.8333 61.6665 60 61.6665H20C18.1666 61.6665 16.6666 63.1665 16.6666 64.9998Z"/>
    </svg>
    
);

export const SvgMrComponent = ({ className, width = "80", height = "80" }) => (
    <svg className={className} width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill='var(--svg-fill-color)' d="M9.25352 49.215L8.95352 48.915C7.65352 47.615 7.65352 45.515 8.95352 44.215L29.2535 23.8817C30.5535 22.5817 32.6535 22.5817 33.9535 23.8817L44.9202 34.8484L66.2202 10.915C67.4868 9.48171 69.7202 9.44838 71.0535 10.7817C72.2868 12.0484 72.3535 14.0484 71.1869 15.3484L47.2868 42.2484C46.0202 43.6817 43.8202 43.7484 42.4535 42.3817L31.6202 31.5484L13.9535 49.215C12.6868 50.515 10.5535 50.515 9.25352 49.215ZM13.9535 69.2151L31.6202 51.5484L42.4535 62.3817C43.8202 63.7484 46.0202 63.6817 47.2868 62.2484L71.1869 35.3484C72.3535 34.0484 72.2868 32.0484 71.0535 30.7817C69.7202 29.4484 67.4868 29.4817 66.2202 30.915L44.9202 54.8484L33.9535 43.8817C32.6535 42.5817 30.5535 42.5817 29.2535 43.8817L8.95352 64.215C7.65352 65.515 7.65352 67.615 8.95352 68.915L9.25352 69.2151C10.5535 70.5151 12.6868 70.5151 13.9535 69.2151Z"/>
    </svg>
);

export const SvgBacktestComponent = ({ className, width = "80", height = "80" }) => (
    <svg className={className} width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill='var(--svg-fill-color)' d="M40.9997 36.3333C33.433 34.3667 30.9997 32.3333 30.9997 29.1667C30.9997 25.5333 34.3663 23 39.9997 23C44.733 23 47.0997 24.8 47.9663 27.6667C48.3663 29 49.4663 30 50.8663 30H51.8663C54.0663 30 55.633 27.8333 54.8663 25.7667C53.4663 21.8333 50.1997 18.5667 44.9997 17.3V15C44.9997 12.2333 42.7663 10 39.9997 10C37.233 10 34.9997 12.2333 34.9997 15V17.2C28.533 18.6 23.333 22.8 23.333 29.2333C23.333 36.9333 29.6997 40.7667 38.9997 43C47.333 45 48.9997 47.9333 48.9997 51.0333C48.9997 53.3333 47.3663 57 39.9997 57C34.4997 57 31.6663 55.0333 30.5663 52.2333C30.0663 50.9333 28.933 50 27.5663 50H26.633C24.3997 50 22.833 52.2667 23.6663 54.3333C25.5663 58.9667 29.9997 61.7 34.9997 62.7667V65C34.9997 67.7667 37.233 70 39.9997 70C42.7663 70 44.9997 67.7667 44.9997 65V62.8333C51.4997 61.6 56.6663 57.8333 56.6663 51C56.6663 41.5333 48.5663 38.3 40.9997 36.3333Z"/>
    </svg>
);
  

export const SvgAccountComponent = ({ className, width = "80", height = "80" }) => (
    <svg className={className} width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill='var(--svg-fill-color)' d="M39.9997 40C47.3663 40 53.333 34.0334 53.333 26.6667C53.333 19.3 47.3663 13.3334 39.9997 13.3334C32.633 13.3334 26.6663 19.3 26.6663 26.6667C26.6663 34.0334 32.633 40 39.9997 40ZM39.9997 46.6667C31.0997 46.6667 13.333 51.1334 13.333 60V63.3334C13.333 65.1667 14.833 66.6667 16.6663 66.6667H63.333C65.1663 66.6667 66.6663 65.1667 66.6663 63.3334V60C66.6663 51.1334 48.8997 46.6667 39.9997 46.6667Z"/>
    </svg>
);