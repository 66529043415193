import React from 'react';
import { H1Mono } from '../uiKit/Fonts';
import { DashboardNav } from '../uiKit/dashboardNav/DashboardNav';
import useAuthCheck from '../authenticate/useAuthCheck';
import './backtest.scss';
import LoadingPage from '../uiKit/loadingStates/LoadingPage';

function Backtest() {

    const isAuthenticated = useAuthCheck(); // Use the updated hook

    // Render loading state while checking authentication
    if (isAuthenticated === null) {
        return <LoadingPage />;
    }

    return (
        <div className='bt-parent_w'>
            <DashboardNav />
            <div className='bt-col_2_w'>
                <H1Mono copy='Backtest Page'/>
            </div>
        </div>
    );
}

export default Backtest