import React from 'react';
import './nav.scss';
import Logo from '../../uiKit/Logo';

function Nav () {
    return (
        <div className='hp-nav-main_w'>
            <Logo />
        </div>
    );
}


export default Nav;