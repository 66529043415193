import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonMainLarge, Link12Black, Link12Grey } from '../uiKit/Buttons';
import { H1Mono } from '../uiKit/Fonts';
import { InputBasic } from '../uiKit/Inputs';
import loginImage from '../assets/login-image.png';

import { toast, Slide } from 'react-toastify';
import './login.scss';


function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const api_url = process.env.REACT_APP_API_URL;

    const showToast = (message) => {
        toast(message, {
            position: "bottom-left",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            className: 'kit-custom-toast-white',
            transition: Slide,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission

        // Validate input fields
        if (!username || !password) {
            showToast('You forgot to fill some fields.');
            return;
        }

        try {
            const response = await fetch(`${api_url}/login`, {
                method: 'POST',
                credentials: 'include', // Needed for cookies to be set
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            if (response.ok) {
                navigate('/mr');
            } else {
                const errorResponse = await response.json(); // Assuming the server sends a JSON error message
                showToast(errorResponse.message || 'Wrong email or password');
            }
        } catch (error) {
            console.error('Error:', error);
            showToast('An unknown error occurred. Please try again later.');
        }
    };

    return (
        <div className='lp-main_w'>
            <div className='lp-col_1_w'>
                <div className='lp-col_1-header_w'>
                    <Link12Black href='/' copy='Home'/>
                </div>
                <div className='lp-col_1-body_w'>
                    <H1Mono copy="Login"/> 
                    <form className='lp-form_w'>
                        <InputBasic label="Username" placeholder='GordonG' type="text" value={username} onChange={e => setUsername(e.target.value)}/>
                        <InputBasic label="Password" placeholder='*************' type="password" value={password} onChange={e => setPassword(e.target.value)}/>
                        <div className='lp-form-cta_w'>
                            <ButtonMainLarge onClick={handleSubmit} copy="Login"/>
                        </div>
                    </form>
                </div>
                <div className='lp-col_1-footer_w'>
                    <Link12Grey href='/register' copy='Signup instead'/>
                    <Link12Grey href='/reset-password' copy='Forgot password'/>
                </div>
            </div>
            <div className='lp-col_2_w'>
                <img className='lp-col_2-image' alt='inspirational shape' src={loginImage} />
            </div>
        </div>
    );
}

export default Login;