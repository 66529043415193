import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSpecificMrTests } from '../../store/specificMrTestsSlice';
import useAuthCheck from '../../authenticate/useAuthCheck';

import io from 'socket.io-client';
const api_url = process.env.REACT_APP_API_URL;
const socket = io(api_url, { withCredentials: true });

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function useMRTestValues(ticker1, ticker2) {
    const dispatch = useDispatch();
    const { specificMrTests } = useSelector((state) => state.specificMrTests);
    const isAuthenticated = useAuthCheck();
  
    const [values, setValues] = useState({
      windowStartDateMonth: null,
      windowStartDateYear: null,
      windowEndDateMonth: null,
      windowEndDateYear: null,
      tsLevel: null,
      esLevel: null,
      eigenvalue: null,
      halfLife: null,
      nonMainMrTests: []
    });
  
    // Process `specificMrTests` to extract desired values
    useEffect(() => {
      const mainMrTest = specificMrTests.find(test => test.is_main_mr_test);
  
      if (mainMrTest) {
        const startDate = new Date(mainMrTest.window_start_date);
        const endDate = new Date(mainMrTest.window_end_date);
        setValues(prevValues => ({
          ...prevValues,
          windowStartDateMonth: monthNames[startDate.getMonth()],
          windowStartDateYear: startDate.getFullYear(),
          windowEndDateMonth: monthNames[endDate.getMonth()],
          windowEndDateYear: endDate.getFullYear(),
          tsLevel: mainMrTest.ts_level,
          esLevel: mainMrTest.es_level,
          eigenvalue: mainMrTest.eigenvalue,
          halfLife: mainMrTest.half_life,
          nonMainMrTests: specificMrTests.filter(test => !test.is_main_mr_test)
        }));
      }
    }, [specificMrTests]);
  
    // WebSocket integration for real-time updates
    useEffect(() => {
      if (isAuthenticated) {
        dispatch(fetchSpecificMrTests({ ticker1, ticker2 }));
  
        // Listen for real-time MR test updates
        socket.on('mrTestUpdate', () => {
          dispatch(fetchSpecificMrTests({ ticker1, ticker2 }));
        });
  
        return () => {
          socket.off('mrTestUpdate');
        };
      }
    }, [dispatch, isAuthenticated, ticker1, ticker2]);
  
    return values;
  }
  
  export default useMRTestValues;