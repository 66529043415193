import React, { useEffect } from 'react';
import { H1Sans } from '../uiKit/Fonts';
import { DashboardNav } from '../uiKit/dashboardNav/DashboardNav';
import useAuthCheck from '../authenticate/useAuthCheck';
import './meanReversion.scss';
import LoadingPage from '../uiKit/loadingStates/LoadingPage';
import LoadingSectionMRListPage from '../uiKit/loadingStates/LoadingSectionMRListPage';
import { MrTableMainTestListRow } from './components/MrTableListRow';
import { ButtonPrimary } from '../uiKit/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMainMrTests } from '../store/mainMrTestsSlice';
import { sidePanelTrigger, SidePanel } from '../uiKit/sidePanel/SidePanel';
import NewMrTestForm from './components/NewMrTestForm';

import io from 'socket.io-client';
const api_url = process.env.REACT_APP_API_URL;
const socket = io(api_url, { withCredentials: true });




function MeanReversion() {
    // consts
    const dispatch = useDispatch();
    const { mainMrTests } = useSelector((state) => state.mainMrTests);
    const isAuthenticated = useAuthCheck();

    // check authentication
    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchMainMrTests());
        }

        // Listen for real-time MR test updates
        socket.on('mrTestUpdate', (data) => {
            console.log(data.message);
            dispatch(fetchMainMrTests());
        });

        return () => {
            socket.off('mrTestUpdate');
        };

    }, [dispatch, isAuthenticated]);

    // Checking for authentication status
    if (isAuthenticated === null) {
        return <LoadingPage />;
    }

    // Check if mainMrTests is not empty before trying to access its properties
    const hasMainMrTests = mainMrTests && mainMrTests.length > 0;

    // clicking on open pannel
    const onClickOpenPanel = () => {
        sidePanelTrigger.open({ title: 'New mean reversion test' });
    };

    // jsx object
    return (
        <>
            <div className='mr-parent_w'>
                <DashboardNav />
                <div className='mr-col_2_w'>
                    <div className='mr-col_2-header_w'>
                        <H1Sans copy='Mean reversion tests'/>
                        <ButtonPrimary onClick={onClickOpenPanel} copy='New test'/>
                    </div>
                    <div className='mr-test_list-main_w'>
                        <div className='mr-test_list-header_w'>
                            <div className='mr-test_list-header_cell_w'>
                                <span>Creation date</span>
                            </div>
                            <div className='mr-test_list-header_cell_w'>
                                <span>Ticker 1</span>
                            </div>
                            <div className='mr-test_list-header_cell_w'>
                                <span>Ticker 2</span>
                            </div>
                            <div className='mr-test_list-header_cell_w'>
                                <span>Start date</span>
                            </div>
                            <div className='mr-test_list-header_cell_w'>
                                <span>End date</span>
                            </div>
                            <div className='mr-test_list-header_cell_w'>
                                <span>Trace Stat Level</span>
                            </div>
                            <div className='mr-test_list-header_cell_w'>
                                <span>Eigen Stat Level</span>
                            </div>
                            <div className='mr-test_list-header_cell_w'>
                                <span>Eigenvalue</span>
                            </div>
                            <div className='mr-test_list-header_cell_w'>
                                <span>Halflife</span>
                            </div>
                            <div className='mr-test_list-header_cell_w'>
                                <span>Status</span>
                            </div>
                            <div className='mr-test_list-header_end_of_line'>
                            </div>
                        </div>
                        {hasMainMrTests ? 
                            mainMrTests.map(test => <MrTableMainTestListRow key={test.id} mainMrObj={test}/>) 
                            : <LoadingSectionMRListPage/>}
                    </div>
                </div>
            </div>
            <SidePanel>
                <NewMrTestForm />
            </SidePanel>
        </>
    );
}

export default MeanReversion