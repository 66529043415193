import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const api_url = process.env.REACT_APP_API_URL;

// Async thunk public monitoring
export const fetchPublicMonitoringData = createAsyncThunk(
  'publicMonitoringData/fetch',
  async (thunkAPI) => {
    try {
      const response = await fetch(
        `${api_url}/public_data/monitor`, 
        { credentials: 'include' }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Async thunk public monitoring
export const fetchToneMonitoringData = createAsyncThunk(
  'toneMonitoringData/fetch',
  async (thunkAPI) => {
    try {
      const response = await fetch(
        `${api_url}/tone_data/monitor`, 
        { credentials: 'include' }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Async thunk public monitoring
export const fetchHistoricStockMonitoringData = createAsyncThunk(
  'historicStockMonitoringData/fetch',
  async (thunkAPI) => {
    try {
      const response = await fetch(
        `${api_url}/historic_stock_data/monitor`, 
        { credentials: 'include' }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);









// Initial state
const initialState = {
    publicMonitoringData: [],
    historicStockMonitoringData: [],
    toneMonitoringData: [],
    isLoading: false,
    error: null,
};












// Slice definition
const monitoringDataSlice = createSlice({
  name: 'monitoringData',
  initialState,
  reducers: {
    // Reducer methods
  },
  extraReducers: {
    //public data
    [fetchPublicMonitoringData.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchPublicMonitoringData.fulfilled]: (state, action) => {
      state.isLoading = false;
      // IMPORTANT line here below
      state.publicMonitoringData = action.payload;
    },
    [fetchPublicMonitoringData.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    //tone data
    [fetchToneMonitoringData.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchToneMonitoringData.fulfilled]: (state, action) => {
      state.isLoading = false;
      // IMPORTANT line here below
      state.toneMonitoringData = action.payload;
    },
    [fetchToneMonitoringData.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    //historic stock data
    [fetchHistoricStockMonitoringData.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchHistoricStockMonitoringData.fulfilled]: (state, action) => {
      state.isLoading = false;
      // IMPORTANT line here below
      state.historicStockMonitoringData = action.payload;
    },
    [fetchHistoricStockMonitoringData.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default monitoringDataSlice.reducer;