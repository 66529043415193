import React from 'react';
import './footer.css';
import { PSmall } from '../../uiKit/Fonts';

export function Footer () {
    return (
        <div className='hp-footer_w'>
            <PSmall copy="V2.0 - Created by hello@bprigent.com"/>
        </div>
    );
};