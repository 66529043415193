import React from 'react';
import './buttons.scss';



export const ButtonMain = ({onClick, copy}) => {
    return (
        <button className='kit-button-main' onClick={onClick}>{copy}</button>
    );
};

export const ButtonMainLarge = ({onClick, copy}) => {
    return (
        <button className='kit-button-main-large' onClick={onClick}>{copy}</button>
    );
};







export const ButtonPrimary = ({onClick, copy}) => {
    return (
        <button className='kit-button-primary' onClick={onClick}>{copy}</button>
    );
};

export const ButtonGreenLarge = ({onClick, copy}) => {
    return (
        <button className='kit-button-green-large' onClick={onClick}>{copy}</button>
    );
};













export const BlackIconOnly = ({ onClick, iconName }) => {
    return (
        <button className='kit-button-icon-black' onClick={onClick}>
            <span className="material-icons">{iconName}</span>
        </button>
    );
};

export const WhiteIconOnly = ({ onClick, iconName }) => {
    return (
        <button className='kit-button-icon-white' onClick={onClick}>
            <span className="material-icons">{iconName}</span>
        </button>
    );
};











export const Link12Black = ({ href, copy }) => {
    return (
        <a className='kit-link-12-black' href={href}>{copy}</a>
    );
};

export const Link12Grey = ({ href, copy }) => {
    return (
        <a className='kit-link-12-grey' href={href}>{copy}</a>
    );
};
