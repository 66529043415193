import React from 'react';
import './KPIDataCard.css';

export function KPIDataCard ({iconName, tag ,kpi ,meta}) {
    function formatKPI(input) {
        if (typeof input === 'number' || (typeof input === 'string' && !isNaN(Number(input)))) {
          const numericValue = Number(input);
          return numericValue.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
        } else if (typeof input === 'string') {
          const date = new Date(input);
          if (!isNaN(date)) {
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            const formattedDate = date.toLocaleDateString(undefined, options);
            return formattedDate;
          }
        }
        return input;
      }

    return (
        <div className='kit-KPIDataCard-main_w'>
            <div className='kit-KPIDataCard-tag_w'>
                <span className="material-icons-12">{iconName}</span>
                <span>{tag}</span>
            </div>
            <span className='kit-KPIDataCard-KPI_font'>{formatKPI(kpi)}</span>
            <span className='kit-KPIDataCard-meta_font'>{meta}</span>
        </div>
    );
};