import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const api_url = process.env.REACT_APP_API_URL;

// Async thunk action for fetching public data
export const fetchToneDataForCompany = createAsyncThunk(
  'toneData/fetch',
  async (companyTicker, thunkAPI) => {
    try {
      const response = await fetch(
        `${api_url}/tone_data/company_ticker/${companyTicker}`, 
        { credentials: 'include' }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Initial state
const initialState = {
  toneData: [],
  isLoading: false,
  error: null,
};

// Slice definition
const toneDataSlice = createSlice({
  name: 'toneData',
  initialState,
  reducers: {
    // Reducer methods
  },
  extraReducers: {
    [fetchToneDataForCompany.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchToneDataForCompany.fulfilled]: (state, action) => {
      state.isLoading = false;
      // IMPORTANT line here below
      state.toneData = action.payload;
    },
    [fetchToneDataForCompany.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default toneDataSlice.reducer;