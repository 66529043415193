import React, { useState } from 'react';
import { ButtonMainLarge } from '../../uiKit/Buttons';
import { InputBasic } from '../../uiKit/Inputs';
import { toast, Slide } from 'react-toastify';


export default function DownloadForm() {
    // Consts
    const api_url = process.env.REACT_APP_API_URL;
    const [tickerName, setTickerName] = useState('');
    const showToast = (message) => {
        toast(message, {
            position: "bottom-left",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            className: 'kit-custom-toast-white',
            transition: Slide,
        });
    };


    

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!tickerName) {
            showToast('You forgot to fill some fields.');
            return;
        }

        try {
            const response = await fetch(`${api_url}/download/new/${tickerName}`, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'}
            });

            if (response.status === 200) {
                const jsonResponse = await response.json();
                showToast(jsonResponse.message);
            } else {
                const jsonResponse = await response.json();
                showToast(jsonResponse.message);
            }

        } catch (error) {
            showToast(error.message);
        }
    };

    return (
        <div>
            <InputBasic label='Ticker name' placeholder='AAPL' type='' value={tickerName} onChange={(e) => setTickerName(e.target.value)}/>

            <ButtonMainLarge onClick={handleSubmit} copy='Download' />
        </div>
    );
};