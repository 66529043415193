import React from 'react';
import './logo.scss';

function Logo () {


    return (    
        <div  className='logo-w'>
            <img className='logo-img' src={require('./cr-logo-white.svg').default} alt='Logo' />
        </div>
    );
}


export default Logo;