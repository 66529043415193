import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import './sidePanel.scss';
import { H3Sans } from '../Fonts';

// This will be the entry point for our imperative API
const sidePanelTrigger = (() => {
  let show = () => {};  // This function will be overwritten
  let hide = () => {};

  return {
    open(props) {
      show(props);
    },
    close() {
      hide();
    },
    register(showFn, hideFn) {
      show = showFn;
      hide = hideFn;
    },
  };
})();




// JSX component
const SidePanel = ({ title, children, onClose }) => {
  return (
    <div className="kit-side-panel-container">
      <div className="kit-side-panel-overlay" onClick={onClose}></div>
      <div className="kit-side-panel">
        <div className='kit-side-panel-header_w'>
            <H3Sans copy={title}/>
        </div>
        {children}
      </div>
    </div>
  );
};




// This component will be used to render the SidePanel to the DOM imperatively
const SidePanelPortal = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [panelTitle, setPanelTitle] = useState('');

  const show = useCallback(({ title, content }) => {
    setPanelTitle(title);
    setVisible(true);
  }, []);

  const hide = useCallback(() => {
    setVisible(false);
  }, []);

  // Register the show and hide functions with our trigger API
  useEffect(() => {
    sidePanelTrigger.register(show, hide);

    // Cleanup the registry on unmount
    return () => {
      sidePanelTrigger.register(null, null);
    };
  }, [show, hide]);

  if (!visible) return null;

  return ReactDOM.createPortal(
    <SidePanel title={panelTitle} onClose={hide}>
      {children}
    </SidePanel>,
    document.body
  );
};




export { sidePanelTrigger, SidePanelPortal as SidePanel };


