import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const api_url = process.env.REACT_APP_API_URL;

// Async thunk action for fetching public data
export const fetchHistoricStockDataForCompany = createAsyncThunk(
  'historicStockData/fetch',
  async (companyTicker, thunkAPI) => {
    try {
      const response = await fetch(
        `${api_url}/historic_stock_data/company_ticker/${companyTicker}`, 
        { credentials: 'include' }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Initial state
const initialState = {
    historicStockData: [],
    isLoading: false,
    error: null,
};

// Slice definition
const historicStockDataSlice = createSlice({
  name: 'historicStockData',
  initialState,
  reducers: {
    // Reducer methods
  },
  extraReducers: {
    [fetchHistoricStockDataForCompany.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchHistoricStockDataForCompany.fulfilled]: (state, action) => {
      state.isLoading = false;
      // IMPORTANT line here below
      state.historicStockData = action.payload;
    },
    [fetchHistoricStockDataForCompany.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default historicStockDataSlice.reducer;