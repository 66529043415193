import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonMain } from '../../uiKit/Buttons';

const LogoutButton = () => {
    const navigate = useNavigate();
    const api_url = process.env.REACT_APP_API_URL;

    const handleLogout = () => {
        fetch(`${api_url}/logout`, {
            method: 'POST',
            credentials: 'include' // Include credentials for session cookie
        })
        .then(response => {
            if (response.ok) {
                console.log('Logout successful.');
                navigate('/login'); // Navigate to login page after successful logout
            } else {
                // If the server response is not ok, log the status
                console.error('Logout failed. Status:', response.status);
            }
        })
        .catch(networkError => {
            // Log network errors (e.g., server not reachable)
            console.error('Network error during logout:', networkError);
        });
    };

    return (
        <ButtonMain onClick={handleLogout} copy="Logout"/>
    );
};

export default LogoutButton;
