import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const api_url = process.env.REACT_APP_API_URL;

// Async thunk action for fetching public data
export const fetchPublicDataForCompany = createAsyncThunk(
  'publicData/fetch',
  async (companyTicker, thunkAPI) => {
    try {
      const response = await fetch(
        `${api_url}/public_data/company_ticker/${companyTicker}`,  
        { credentials: 'include' }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Initial state
const initialState = {
  publicData: [],
  isLoading: false,
  error: null,
};

// Slice definition
const publicDataSlice = createSlice({
  name: 'publicData',
  initialState,
  reducers: {
    // Reducer methods
  },
  extraReducers: {
    [fetchPublicDataForCompany.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchPublicDataForCompany.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.publicData = action.payload;
    },
    [fetchPublicDataForCompany.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default publicDataSlice.reducer;

