import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const api_url = process.env.REACT_APP_API_URL;

// Async thunk action for fetching public data
export const fetchUserData = createAsyncThunk(
  'userData/fetch',
  async (thunkAPI) => {
    try {
      const response = await fetch(
        `${api_url}/user`,
        { credentials: 'include' }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Initial state
const initialState = {
    userData: [],
    isLoading: false,
    error: null,
};

// Slice definition
const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    // Reducer methods
  },
  extraReducers: {
    [fetchUserData.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchUserData.fulfilled]: (state, action) => {
      state.isLoading = false;
      // IMPORTANT line here below
      state.userData = action.payload;
    },
    [fetchUserData.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default userDataSlice.reducer;