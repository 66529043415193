import React from "react";
import './inputs.scss';
import { P12Mono } from "./Fonts";

export function InputBasic ({label,placeholder,type,value,onChange}) {
    return (
        <div className="kit-input-parent_w">
            <P12Mono copy={label}/>
            <input className="kit-input-child_w" placeholder={placeholder} type={type} value={value} onChange={onChange} />
        </div>
    );
};


export const InputDropdown = ({ label, name, value, onChange, options }) => {
    return (
      <div className="kit-ddinput-parent_w">
        <P12Mono copy={label}/>
        <select className="kit-ddinput-child_w" name={name} id={name} value={value} onChange={onChange}>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  };

  