import React from 'react';
import './loadingPage.scss';

function LoadingPage() {

    return (
        <div className='loadingPage-parent_w'>
            <div className='loadingPage-col1'>
                <div className='loadingPage-col1-header'>
                    <div className='loadingPage-col1-header-icon_1'></div>
                    <div className='loadingPage-col1-header-icon_1'></div>
                </div>
                <div className='loadingPage-col1-header-icon_1'></div>
            </div>
            <div className='loadingPage-col2'>
                <div className='loadingPage-col2-title'></div>
                <div className='loadingPage-col2-button'></div>
            </div>
        </div>
    )
};

export default LoadingPage;