import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

export const EsTsGroupedBarChart = ({ data }) => {
    return (
        <BarChart
            width={600}
            height={300}
            data={data}
            margin={{
                top: 20, right: 30, left: 20, bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="period" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="ts_level" fill="#8884d8" name="TS Level" />
            <Bar dataKey="es_level" fill="#82ca9d" name="ES Level" />
        </BarChart>
    );
};