import React from 'react';
import Nav from './components/Nav';
import './homePage.scss';
import { H1Sans } from '../uiKit/Fonts';
import { Footer } from './components/Footer';
import AuthBlock from '../authenticate/AuthBlock.js';

function HomePage () {

    return (
        <div className='hp-parent_w'>
            <Nav />
            <div className='hp-hero_w'>
                <div className='hp-hero-h1_wrapper'>
                    <H1Sans copy="Carbonrule makes software for quantitative traders." />
                    <div className='hp-authblock_w'>
                    <AuthBlock />
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}


export default HomePage;