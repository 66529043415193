import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const api_url = process.env.REACT_APP_API_URL;

// Async thunk action for fetching public data
export const fetchSpecificMrTests = createAsyncThunk(
  'specificMrTests/fetch',
  async ({ticker1, ticker2}, thunkAPI) => {
    try {
      const response = await fetch(
        `${api_url}/mr/specific-mr-tests/${ticker1}-${ticker2}`, 
        { credentials: 'include' }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Initial state
const initialState = {
    specificMrTests: [],
    isLoading: false,
    error: null,
};

// Slice definition
const specificMrTestsSlice = createSlice({
  name: 'specificMrTests',
  initialState,
  reducers: {
    // Reducer methods
  },
  extraReducers: {
    [fetchSpecificMrTests.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchSpecificMrTests.fulfilled]: (state, action) => {
      state.isLoading = false;
      // IMPORTANT line here below
      state.specificMrTests = action.payload;
    },
    [fetchSpecificMrTests.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default specificMrTestsSlice.reducer;