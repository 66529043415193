import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const api_url = process.env.REACT_APP_API_URL;

// Fetching company data for 1 company
export const fetchCompanyData = createAsyncThunk(
  'companyData/fetch',
  async (companyTicker, thunkAPI) => {
    try {
      const response = await fetch(
        `${api_url}/company_data/${companyTicker}`, 
        { credentials: 'include' }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch company data');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Fetching all company data
export const fetchAllCompanyData = createAsyncThunk(
    'companyData/fetchAll',
    async (thunkAPI) => {
      try {
        const response = await fetch(
          `${api_url}/download/company_data`, 
          { credentials: 'include' }
        );
        if (!response.ok) {
          throw new Error('Failed to fetch company data');
        }
        const data = await response.json();
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  );

// Initial state
const initialState = {
    companyData: null, // Individual company data
    allCompanyData: [], // All companies data
    isLoading: false,
    error: null,
};

// Slice definition
const companyDataSlice = createSlice({
  name: 'companyData',
  initialState,
  reducers: {
    // Reducer methods (if you have any)
  },
  extraReducers: {
    // single company data
    [fetchCompanyData.pending]: (state) => {
        state.isLoading = true;
    },
    [fetchCompanyData.fulfilled]: (state, action) => {
        state.isLoading = false;
        state.companyData = action.payload;
    },
    [fetchCompanyData.rejected]: (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
    },

    // all company data
    [fetchAllCompanyData.pending]: (state) => {
        state.isLoading = true;
    },
    [fetchAllCompanyData.fulfilled]: (state, action) => {
        state.isLoading = false;
        state.allCompanyData = action.payload;
    },
    [fetchAllCompanyData.rejected]: (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
    },
  },
});

export default companyDataSlice.reducer;
