import React from 'react';
import './navItem.scss';
import { NavLink } from 'react-router-dom';


export function NavItemIcon ({to_link, SvgComponent}) {
    return (
        <NavLink className='kit-dashboard_nav-icon_w' to={to_link}>
            <SvgComponent 
                className='kit-dashboard_nav-icon_svg_file' 
                width = "16"
                height = "16"
            />
        </NavLink>
    );
};