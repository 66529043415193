import React from 'react';
import './resultCard.scss';
import { P12Mono } from '../../uiKit/Fonts';

export function ResultCard ({label, value}) {
    return(
        <div className='kit-result_card-parent_w'>
            <P12Mono copy={label}/>
            <p className='kit-result_card-value'>{value}</p>
        </div>
    );
};