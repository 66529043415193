import React, { useState, useEffect } from 'react';
import LoginButton from './authButtons/LoginButton';
import RegisterButton from './authButtons/RegisterButton';
import DashboardButton from './authButtons/DashboardButton';
import "./authBlock.scss";

const AuthBlock = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const api_url = process.env.REACT_APP_API_URL;
  console.log(api_url);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await fetch(`${api_url}/check-session`, {
          credentials: 'include',
        });
        const data = await response.json();
        setIsAuthenticated(data.isAuthenticated);
      } catch (error) {
        console.error('Error checking session:', error);
      }
    };

    checkSession();
  }, [api_url]);

  return (
    <div>
      {isAuthenticated ? (
        <div className='authBlock-cta_w'>
          <DashboardButton />
        </div>
      ) : (
        <div className='authBlock-cta_w'>
          <LoginButton />
          <RegisterButton />
        </div>
      )}
    </div>
  );
};

export default AuthBlock;