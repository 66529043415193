import React from 'react';
import './DashboardNav.scss';
import { NavItemIcon } from './NavItem';

import { SvgAccountComponent, SvgBacktestComponent, SvgMrComponent, SvgDownloadComponent } from '../../assets/SvgComponents';


export function DashboardNav () {
    
    // return UI element
    return (
        <div className='dn-main_w'>
            <div className='dn-body_w'>
                <NavItemIcon to_link="/download" SvgComponent={SvgDownloadComponent} />
                <NavItemIcon to_link="/mr" SvgComponent={SvgMrComponent} />
                <NavItemIcon to_link="/backtest" SvgComponent={SvgBacktestComponent} />
            </div>
            <div className='dn-footer_w'>
                <NavItemIcon to_link="/account" SvgComponent={SvgAccountComponent} />
            </div>
        </div>
    );
};