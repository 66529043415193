import React from 'react';
import './fonts.scss';


export const H1Sans = ({copy}) => {
    return (
        <h1 className='kit-font-h1-sans'>{copy}</h1>
    );
};

export const H1Mono = ({copy}) => {
    return (
        <h1 className='kit-font-h1-mono'>{copy}</h1>
    );
};




export const H2Sans = ({copy}) => {
    return (
        <h1 className='kit-font-h2-sans'>{copy}</h1>
    );
};




export const H3Sans = ({copy}) => {
    return (
        <h3 className='kit-font-h3-sans'>{copy}</h3>
    );
};

export const H3Mono = ({copy}) => {
    return (
        <h3 className='kit-font-h3-mono'>{copy}</h3>
    );
};









export const PSmall = ({copy}) => {
    return (
        <p className='kit-font-p_small_grey'>{copy}</p>
    );
};



export const P14Sans = ({copy}) => {
    return (
        <p className='kit-font-p_14-Sans'>{copy}</p>
    );
};


export const P12Mono = ({copy}) => {
    return (
        <p className='kit-font-p_small_grey-mono'>{copy}</p>
    );
};