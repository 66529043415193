import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonPrimary } from '../../uiKit/Buttons';

const DashboardButton = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/mr');
    };

    return (
        <ButtonPrimary onClick={handleClick} copy="Dashboard"/>
    );
};

export default DashboardButton;