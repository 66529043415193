import React from "react";
import './downloadRow.scss';
import { formatDate } from '../../mr/components/utils';
import { BlackIconOnly } from '../../uiKit/Buttons';
import { toast, Slide } from 'react-toastify';

export default function DownloadRow({company}) {

    // show toasts
    const showToast = (message) => {
        toast(message, {
            position: "bottom-left",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            className: 'kit-custom-toast-white',
            transition: Slide,
        });
    };

    const handleDelete = async () => {
        
        const isConfirmed = window.confirm(`Are you sure you want to delete all data for ${company.ticker_symbol}?`);

        if (isConfirmed) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/download/delete/${company.ticker_symbol}`, {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {'Content-Type': 'application/json'}
                });

                if (response.status === 200) {
                    const jsonResponse = await response.json();
                    showToast(jsonResponse.message);
                } else {
                    const jsonResponse = await response.json();
                    showToast(jsonResponse.message);
                }

            } catch (error) {
                showToast(error.message);
            }
        } else {
            showToast('Deletion cancelled by the user.');
        };
    };

    return (
        <div className="do-row-parent_w">
            <div className="do-row-cell_w">
                <span className="do-row-text_w">{company.ticker_symbol}</span>
            </div>
            <div className="do-row-cell_w">
                <span className="do-row-text_w">{company.name}</span>
            </div>
            <div className="do-row-cell_w">
                <span className="do-row-text_w">{formatDate(company.earliest_timestamp)}</span>
            </div>
            <div className="do-row-cell_w">
                <span className="do-row-text_w">{formatDate(company.latest_timestamp)}</span>
            </div>
            <div className="do-row-cell_w">
                <span className="do-row-text_w">{company.download_status}</span>
            </div>
            <div className="do-row-cell_w">
                <BlackIconOnly onClick={handleDelete} iconName='delete'/>
            </div>
        </div>
    )
};