import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const api_url = process.env.REACT_APP_API_URL;

// Async thunk action for fetching public data
export const fetchSentimentDataForCompany = createAsyncThunk(
  'sentimentData/fetch',
  async (companyTicker, thunkAPI) => {
    try {
      const response = await fetch(
        `${api_url}/sentiment_data/company_ticker/${companyTicker}`,
        { credentials: 'include' }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Initial state
const initialState = {
    sentimentData: [],
    isLoading: false,
    error: null,
};

// Slice definition
const sentimentDataSlice = createSlice({
  name: 'sentimentData',
  initialState,
  reducers: {
    // Reducer methods
  },
  extraReducers: {
    [fetchSentimentDataForCompany.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchSentimentDataForCompany.fulfilled]: (state, action) => {
      state.isLoading = false;
      // IMPORTANT line here below
      state.sentimentData = action.payload;
    },
    [fetchSentimentDataForCompany.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default sentimentDataSlice.reducer;